import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    // {
    //     path: "/impressum",
    //     name: "Impressum",
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () =>
    //         import(/* webpackChunkName: "about" */ "@/views/Impressum.vue"),
    // },
    // {
    //     path: "/privacy",
    //     name: "Privacy",
    //     component: () =>
    //         import(/* webpackChunkName: "privacy" */ "@/views/Privacy.vue"),
    // }
    // {
    //     path: "/bajohe",
    //     name: "Bachelor Thesis",
    //     component: () =>
    //         import(/* webpackChunkName: "bajo" */ "@/views/students/BaJohannes.vue"),
    // },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
