
    import { defineComponent, ref, reactive, watch, onMounted, computed} from "vue";
    import Footer from "@/components/Footer.vue"; // @ is an alias to /src

    export default defineComponent({
        name: 'Home',
        components: {
            Footer,
        },
        setup() {
            // data
            const email = 'matthias.schmidmaier@ifi.lmu.de';


            // I'm currently affiliated with the <a href="https://www.medien.ifi.lmu.de/" target="_blank">HCI research group</a> at LMU
            // Munich. My general field of research is application-oriented Affective Computing, with
            // a focus on non-verbal communication in virtual environments &amp; empathic agents.
            // <section :v-if="false">
            //     <p>If you are interested in any of these topics, or in other cool stuff <a v-bind:href="'mailto:'+email">
            //         get in touch</a> :)
            //     </p>
            //     <p class="signature">
            //         <!--<img class="profile-img" src="@/assets/img/ms.jpg"/>-->
            //         Cheers,
            //     </p>
            // </section>

            return {
                email
            };
        }
    })
